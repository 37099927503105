import React from "react";

export const Howtobuy = (props) => {
  return (
    <div
      id="howtobuy"
      style={{
        padding: "50px",
        background: "linear-gradient(to bottom, #ffe591, #ffe894)",
      }}
    >
      <div className="container">
        <div
          className="section-title"
          style={{
            marginBottom: "40px",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "4rem",
              color: "black",
              fontWeight: "bold",
              marginBottom: "10px",
              fontFamily: "'Sour Gummy', serif",
            }}
          >
            How to Buy
          </h2>
        </div>
        <div className="row justify-content-center">
          {/* Card 1 */}
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{
                border: "none",
                borderRadius: "15px",
                overflow: "hidden",
                background: "#FFD95E",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                height: "21rem",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow =
                  "0px 8px 30px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0px)";
                e.currentTarget.style.boxShadow =
                  "0px 4px 20px rgba(0, 0, 0, 0.1)";
              }}
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <h5
                  className="card-title"
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "2.5rem",
                    fontFamily: "'Sour Gummy', serif",
                  }}
                >
                  CREATE WALLET
                </h5>
                <p
                  className="card-text"
                  style={{
                    fontSize: "1.5rem",
                    color: "#555",
                    fontFamily: "'Sour Gummy', serif",
                  }}
                >
                  Download Phantom or your wallet of choice from the app store
                  or Google Play for free. Desktop users: download the Google
                  Chrome extension by going to Phantom.
                </p>
              </div>
            </div>
          </div>

          {/* Repeat for other cards */}
          {[ 
            {
              title: "GET SOME SOL",
              text: "Have SOL in your wallet to switch to $PUTOTO. If you don't have any SOL, buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.",
            },
            {
              title: "GO TO JUPITER",
              text: "Connect to JUPTER. Make steps align properly pasted signature configuration approved flowigned.",
            },
            {              title: "SWITCH FOR $PUTOTO",
              text: "We have zero taxes so you don't need to worry about buying with a specific slippage. Buy directly on Phantom if needed.",
            },
          ].map((card, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <div
                className="card"
                style={{
                  border: "none",
                  borderRadius: "15px",
                  overflow: "hidden",
                  background: "#FFD95E",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  height: "21rem",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow =
                    "0px 8px 30px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0px)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 20px rgba(0, 0, 0, 0.1)";
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  <h5
                    className="card-title"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "2.2rem",
                      fontFamily: "'Sour Gummy', serif",
                    }}
                  >
                    {card.title}
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.5rem",
                      color: "#555",
                      fontFamily: "'Sour Gummy', serif",
                    }}
                  >
                    {card.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

