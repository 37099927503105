import React, { useState } from "react";
import Lottie from "react-lottie";
import cartAnimation from "../data/lotties/cart.json"; // Update with the correct path to your Lottie file

export const Navigation = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cartAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Handle the toggle button click
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={handleToggle} // Trigger state change when clicked
          >
            <span className="sr-only">Toggle navigation</span>
            <span
              className="icon-bar"
              style={{
                backgroundColor: isExpanded ? "#ff6a6a" : "#ffe591", // Change icon-bar color when expanded
                transform: isExpanded ? "rotate(45deg) translate(5px, 5px)" : "none", // Rotate and move icon-bar to form a close icon
                transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition
              }}
            ></span>
            <span
              className="icon-bar"
              style={{
                backgroundColor: isExpanded ? "#ff6a6a" : "#ffe591",
                opacity: isExpanded ? 0 : 1, // Hide the middle icon-bar when expanded
                transition: "opacity 0.3s ease", // Smooth transition for opacity
              }}
            ></span>
            <span
              className="icon-bar"
              style={{
                backgroundColor: isExpanded ? "#ff6a6a" : "#ffe591",
                transform: isExpanded ? "rotate(-45deg) translate(5px, -5px)" : "none", // Rotate the bottom icon-bar when expanded
                transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition
              }}
            ></span>
          </button>
          <a className="navbar-brand page-scroll" href="https://pump.fun/coin/8wrBySJMeGEYwJkBQnCjjihMU7pzCWfoC154v1ZJpump" target="_blank" rel="noopener noreferrer">
            <button
              type="button"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                borderRadius: "20px",
                border: `2px solid ${isExpanded ? "#ffe200" : "#ff6a6a"}`,
                fontFamily: "'Sour Gummy', serif",
                fontWeight: "bold",
                backgroundColor: isExpanded ? "#ff6a6a" : "#ffe591",
                color: isExpanded ? "#ffe591" : "#ff6a6a",
                padding: "0px 15px",
                cursor: "pointer",
                transition: "all 0.3s ease",
              }}
            >
              <Lottie options={defaultOptions} height={40} width={40} />
              $PUTOTO
            </button>
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul
            className="nav navbar-nav navbar-center"
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: "60px",
              listStyleType: "none",
              margin: 0,
              padding: 0,
            }}
          >
            <li>
              <a href="#header" className="page-scroll" style={{ padding: 0 }}>
                <img
                  src="\img\putoto_nobg-removebg.png"
                  alt="Home"
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                  }}
                />
              </a>
            </li>

            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#howtobuy" className="page-scroll">
                How to buy
              </a>
            </li>
            <li>
              <a href="#roadmap" className="page-scroll">
                Roadmap
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
