import React from "react";

export const Roadmap = () => {
  const roadmapData = [
    {
      title: "A Potato",
      image: "/img/potato-removebg.png",
      position: "right", // Position title to the right of image
    },
    {
      title: "$PUTOTO",
      description: "To the moon!",
      image: "/img/putoto_laydown.png",
      position: "left", // Position description to the left of image
    },
    {
      image: "/img/putoto_nobg-removebg.png",
      position: "center", // Position description to the left of image
    },
  ];

  return (
    <div id="roadmap" style={styles.container}>
      <div style={styles.sectionTitle}>
        <h2 style={styles.title}>Roadmap</h2>
      </div>
      <div style={styles.roadmapWrapper}>
        {/* SVG for Twisting Line */}
        <svg
          style={styles.curveLine}
          viewBox="0 0 200 790"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 0 Q50 100 100 200 T100 400 T100 600"
            stroke="black"
            fill="transparent"
            strokeWidth="3"
          />
        </svg>
        {roadmapData.map((item, index) => (
          <div key={index} style={styles.stageWrapper(item.position)}>
            <div style={styles.textWrapper(item.position)}>
              <h3 style={styles.stageTitle}>{item.title}</h3>
              {item.description && (
                <p style={styles.stageDescription}>{item.description}</p>
              )}
            </div>
            <div style={styles.imageWrapper(item.position)}>
              <img
                src={item.image}
                alt={item.title}
                style={styles.image}
              />
            </div>
          </div>
        ))}
      </div>
      {/* Social Links with SVG icons */}
      <div style={styles.socialLinks}>
        <a
          href="https://x.com/putotosolana"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.socialLink}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            style={{ width: "40px", height: "40px" }}
          >
            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z" />
          </svg>
        </a>
        <a
          href="https://t.me/putotocto"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.socialLink}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
            style={{ width: "40px", height: "40px" }}
          >
            <path d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z" />
          </svg>
        </a>
      </div>
      {/* Footer */}
      <div style={styles.footer}>
        <p>© 2024 Putoto. All Rights Reserved.</p>
      </div>
    </div>
  );
};

// Styling
const styles = {
  container: {
    backgroundColor: "#ffa4a4",
    padding: "50px 20px 10px 20px",
    textAlign: "center",
  },
  sectionTitle: {
    marginBottom: "40px",
    fontFamily: "'Sour Gummy', serif",
  },
  title: {
    fontSize: "4rem",
    fontWeight: "bold",
    color: "#333",
    fontFamily: "'Sour Gummy', serif",
  },
  roadmapWrapper: {
    position: "relative",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "50px 0",
  },
  curveLine: {
    position: "absolute",
    left: "50%",
    top: "0",
    width: "200px",
    height: "100%",
    transform: "translateX(-50%)",
  },
  stageWrapper: (position) => ({
    display: "flex",
    flexDirection: position === "right" ? "row-reverse" : "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "100px",
    position: "relative",
  }),
  textWrapper: (position) => ({
    textAlign: position === "right" ? "right" : "left",
    margin: "0 20px",
  }),
  imageWrapper: (position) => ({
    width: "150px",
    height: "150px",
    marginBottom: "20px",
    overflow: "visible",
    position: "relative",
    zIndex: 1,
    marginLeft: position === "right" ? "120px" : "0",
    marginRight: position === "left" ? "120px" : "0",
  }),
  image: {
    width: "150px",
    height: "auto",
  },
  stageTitle: {
    fontSize: "26px",
    fontWeight: "600",
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "'Sour Gummy', serif",
  },
  stageDescription: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#ffe591",
  },
  socialLinks: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    marginTop: "20px",
  },
  socialLink: {
    color: "black",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
  },
  footer: {
    marginTop: "50px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#333",
  },
};

export default Roadmap;
